<template>
  <ion-app :key="key">
    <!-- <ionMenus/> -->
    <div id="app1">
      <div id="recaptcha-placeholder"></div>
      <div>
        <ion-menu contentId="my-content" side="start">
          <!-- Side bar start -->
          <ion-header>
            <ion-toolbar color="secondary">
              <div
                style="
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                  justify-content: space-between;
                "
              >
                <ion-title style="text-align: start"
                  >{{ $t('frontend.menu.menu') }}
                </ion-title>

                <Language />
                <ion-button slot="end" fill="clear" @click="closeMenu">
                  <ion-icon name="close"></ion-icon>
                </ion-button>
              </div>

              <ion-item>
                <ion-chip
                  ref="loginButtonSide"
                  outline
                  :key="keyUserLogin"
                  v-if="!customerName && !getAuthenticated"
                  @click="showLoginMember = !showLoginMember"
                >
                  {{ $t('frontend.menu.log') }}
                </ion-chip>
                <ion-cardd
                  ref="loginMenuSide"
                  v-if="showLoginMember && !customerName && !getAuthenticated"
                  style=""
                >
                  <ion-item>
                    <ion-chip outline @click="logging('', '', false)">
                      <span>{{ $t('frontend.menu.membersLogin') }}</span>
                    </ion-chip>
                  </ion-item>
                  <ion-item>
                    <ion-chip outline @click="logging('', '', true)">
                      <span>{{ $t('frontend.menu.membersRegister') }}</span>
                    </ion-chip>
                  </ion-item>
                </ion-cardd>
              </ion-item>

              <!--Show Only whe Logged-->
              <ion-item
                class="item-logout"
                :key="keyUserLogin + 'L'"
                v-if="customerName"
              >
                <ion-chip
                  outline
                  @click="cerrarSesion(), (showEditUser = false)"
                  class="button-logout"
                  v-tooltip="{
                    content: $t('frontend.menu.logout'),
                    placement: 'bottom',
                    offset: '0, 10'
                  }"
                >
                  <span
                    class="iconify"
                    data-icon="ic:twotone-logout"
                    data-inline="false"
                  ></span>
                </ion-chip>
              </ion-item>
              <ion-item
                v-if="
                  !getAuthenticated &&
                  !this.$store.state.restaurantActive.restaurantName
                "
              >
                <ion-button @click="toggleAboutImenu">
                  {{ $t('frontend.menu.aboutImenu') }}
                </ion-button>
                <ion-icon
                  name="close"
                  slot="end"
                  v-if="showCloseIcon"
                  @click="toggleAboutImenu"
                ></ion-icon>
              </ion-item>
              <ion-item
                v-if="
                  showAboutImenu &&
                  !this.getAuthenticated &&
                  !this.$store.state.restaurantActive.restaurantName
                "
              >
                <div class="scrollable-content justificado">
                  {{ truncatedText }}
                  <span v-if="showFullText">
                    {{ fullText }}
                  </span>
                  <a v-if="!showFullText" @click="showFullText = true">{{
                    $t('frontend.menu.seeMore')
                  }}</a>
                  <a v-else @click="showFullText = false">{{
                    $t('frontend.menu.seeLess')
                  }}</a>
                </div>
              </ion-item>
              <!--NEW OPTION ON MAIN HAMBURGER-->
              <ion-item
                class="clickable"
                @click="goToJournal()"
                :key="!keyUserLogin + 'L'"
                v-if="
                  customerName &&
                  !getAuthenticated &&
                  !this.$store.state.restaurantActive.restaurantName
                "
              >
                {{ $t('frontend.menu.foodAndMoodJournal') }}
              </ion-item>
              <!-- <ion-item
                  v-if="
                    customerName  && !getAuthenticated && !this.$store.state.restaurantActive.restaurantName
                  "
                  @click="getReservation()"
                >
                  {{ $t("frontend.menu.reservation") }}
                  <ion-badge
                    v-if="$store.state.allReservations.length > 0"
                    slot="end"
                    color="secondary"
                    >{{ $store.state.allReservations.length }}</ion-badge
                  >
                </ion-item>
                <ion-item
                  v-if="customerName  && !getAuthenticated && !this.$store.state.restaurantActive.restaurantName
                  "
                  @click="getOrderList"
                >
                  {{ $t("frontend.menu.orders") }}
                  <ion-badge
                    v-if="$store.state.allOrders.length > 0"
                    slot="end"
                    color="secondary"
                    >{{ $store.state.allOrders.length }}</ion-badge
                  >
                </ion-item>
                <ion-item v-if="customerName  && !getAuthenticated && !this.$store.state.restaurantActive.restaurantName" @click="getCreditList">
                  {{ $t("frontend.menu.credit") }}
                  <ion-badge
                    v-if="$store.state.allCustomerCredit.length > 0"
                    slot="end"
                    color="secondary"
                    >{{ $store.state.allCustomerCredit.length }}</ion-badge
                  >
                </ion-item>
                <ion-item v-if="!this.$store.state.restaurantActive.restaurantName" 
                  @click="goHome"
                >
                  {{ $t("frontend.menu.menu") }}</ion-item
                >
                <ion-item v-if="!this.$store.state.restaurantActive.restaurantName"
                  @click="goCatering()"
                >
                  {{ $t("frontend.menu.menuCatering") }}</ion-item
                > -->
              <ion-item
                class="clickable"
                v-if="!$store.state.restaurantActive.RestaurantBussines"
                @click="goAds()"
              >
                {{ $t('frontend.menu.ads') }}
              </ion-item>
              <ion-item
                class="clickable"
                v-if="!$store.state.restaurantActive.RestaurantBussines"
                @click="chat(false)"
              >
                {{ $t('frontend.menu.chatImenu') }}
              </ion-item>
              <!-- <ion-item @click="goToJournal()" v-if="!$store.state.restaurantActive.RestaurantBussines">
                {{ $t("frontend.menu.foodAndMoodJournal") }}
              </ion-item> -->
              <ion-item
                class="clickable"
                @click="trackingPackage()"
                v-if="!$store.state.restaurantActive.RestaurantBussines"
              >
              {{ $t('frontend.menu.trackYourPackage') }}
              </ion-item>
              <ion-item
                v-if="!$store.state.restaurantActive.RestaurantBussines"
              >
                <ion-title class="menu-col-12" style="text-align: center"
                  ><a
                    href="https://imenuapps.com/"
                    target="_blank"
                    style="text-decoration: none"
                    >iMenuApps®</a
                  ><br />
                  <ion-note>v{{ appVersion }}</ion-note> |
                  <ion-note>{{ devUserTimezone }}</ion-note>
                </ion-title>
              </ion-item>
              <!--END NEW OPTION ON MAIN HAMBURGER-->
            </ion-toolbar>
          </ion-header>
          <!-- Side bar end -->
          <ion-content padding class="menu ion-page">
            <ion-content v-show="restaurantSelectedId !== ''">
              <ion-item> </ion-item>

              <div
                style="margin-top: 20px"
                v-if="hasOptionToShowOtherRestaurants()"
              >
                <ion-select
                  interface="alert"
                  icon="add"
                  :ok-text="$t('backoffice.form.messages.buttons.ok')"
                  :cancel-text="$t('backoffice.form.messages.buttons.dismiss')"
                  :value="restaurantSelectedId"
                  :placeholder="$t('frontend.menu.restaurant')"
                  @ionChange="changeRestaurant($event.target.value)"
                >
                  <ion-select-option
                    v-for="res in allRestaurant"
                    :key="res._id"
                    :value="res._id"
                  >
                    {{ res.Name }}
                  </ion-select-option>
                </ion-select>
              </div>
              <!--BUSINESS HAMBURGUER MENU-->
              <ion-card :key="storeKey + 'SK'">
                <div class="logo" style="margin-bottom: 5px">
                  <img :src="$store.state.restaurantActive.restaurantLogo" />
                </div>

                <div style="display: flex; justify-content: center">
                  <div v-if="$store.state.restaurantActive.restaurantFacebok">
                    <a
                      :href="$store.state.restaurantActive.restaurantFacebok"
                      v-if="
                        $store.state.restaurantActive.restaurantFacebok !== ''
                      "
                      target="_blanc"
                      v-tooltip="'Facebook'"
                    >
                      <span
                        class="iconify"
                        color="#3b5998"
                        data-icon="ion-logo-facebook"
                        data-inline="false"
                      ></span>
                    </a>
                  </div>
                  <div v-if="$store.state.restaurantActive.restaurantTwitter">
                    <a
                      :href="$store.state.restaurantActive.restaurantTwitter"
                      target="_blanc"
                      v-tooltip="'Twitter'"
                    >
                      <span
                        class="iconify"
                        color="#1DA1F2"
                        data-icon="ion-logo-twitter"
                        data-inline="false"
                      ></span>
                    </a>
                  </div>
                  <div v-if="$store.state.restaurantActive.restaurantInstagram">
                    <a
                      :href="$store.state.restaurantActive.restaurantInstagram"
                      v-if="
                        $store.state.restaurantActive.restaurantInstagram != ''
                      "
                      target="_blanc"
                      v-tooltip="'Instagram'"
                    >
                      <span
                        class="iconify"
                        color="#8a3ab9"
                        data-icon="ion-logo-instagram"
                        data-inline="false"
                      ></span>
                    </a>
                  </div>
                  <div v-if="$store.state.restaurantActive.restaurantYoutube">
                    <a
                      :href="$store.state.restaurantActive.restaurantYoutube"
                      v-if="
                        $store.state.restaurantActive.restaurantYoutube != ''
                      "
                      target="_blanc"
                      v-tooltip="'Toutube'"
                    >
                      <span
                        class="iconify"
                        color="#FF0000"
                        data-icon="ion-logo-youtube"
                        data-inline="false"
                      ></span>
                    </a>
                  </div>
                  <div v-if="$store.state.restaurantActive.UrlLocation">
                    <a
                      @click="shareUrlLocation()"
                      v-if="$store.state.restaurantActive.UrlLocation != ''"
                      target="_blanc"
                      v-tooltip="$t('frontend.tooltips.shareLocation')"
                    >
                      <span
                        class="iconify"
                        data-icon="si-glyph:pin-location-2"
                        data-inline="false"
                      ></span>
                    </a>
                  </div>
                </div>

                <ion-item
                  class="clickable"
                  v-if="restaurantSelected"
                  @click="goAbout"
                >
                  {{ $t('frontend.menu.about') }}</ion-item
                >
                <ion-item
                  class="clickable"
                  v-if="
                    restaurantSelected &&
                    hasOptionToShowMenu() &&
                    $store.state.restaurantActive.RestaurantBussines
                  "
                  @click="goHome"
                >
                  {{ $t('frontend.menu.menu') }}</ion-item
                >
                <ion-item
                  class="clickable"
                  v-if="hasOptionToShowCatering()"
                  @click="goCatering()"
                >
                  {{ $t('frontend.menu.menuCatering') }}</ion-item
                >

                <ion-item
                  class="clickable"
                  :key="!keyUserLogin + 'L'"
                  v-if="restaurantSelected && customerName && !getAuthenticated"
                  @click="goToJournal()"
                >
                  {{ $t('frontend.menu.foodAndMoodJournal') }}</ion-item
                >
                <ion-item
                  class="clickable"
                  v-if="restaurantSelected && hasOptionToShowTicket()"
                  @click="showTickets()"
                >
                  {{ $t('frontend.menu.ticketMenu') }}
                  <ion-badge
                    v-if="$store.state.allTickets.length > 0"
                    slot="end"
                    color="secondary"
                    >{{ $store.state.allTickets.length }}</ion-badge
                  ></ion-item
                >
                <ion-item
                  class="clickable"
                  v-if="
                    customerName &&
                    !getAuthenticated &&
                    restaurantSelected &&
                    hasOptionToShowReservation() &&
                    hasCardPayRes()
                  "
                  @click="getReservation()"
                >
                  {{ $t('frontend.menu.reservation') }}
                  <ion-badge
                    v-if="$store.state.allReservations.length > 0"
                    slot="end"
                    color="secondary"
                    >{{ $store.state.allReservations.length }}</ion-badge
                  ></ion-item
                >
                <ion-item
                  class="clickable"
                  v-if="
                    customerName &&
                    !getAuthenticated &&
                    restaurantSelected &&
                    $store.state.restaurantActive.RestaurantBussines
                  "
                  @click="getOrderList"
                >
                  {{ $t('frontend.menu.orders') }}
                  <ion-badge
                    v-if="$store.state.allOrders.length > 0"
                    slot="end"
                    color="secondary"
                    >{{ $store.state.allOrders.length }}</ion-badge
                  >
                </ion-item>
                <ion-item
                  class="clickable"
                  @click="
                    restaurantSelected
                      ? goMyAccount()
                      : logging(email, '', false),
                      (showEditUser = false)
                  "
                  :key="!keyUserLogin + 'L'"
                  v-if="restaurantSelected && customerName && !getAuthenticated"
                >
                  My profile</ion-item
                >
                <ion-item
                  class="clickable"
                  v-if="customerName && !getAuthenticated && restaurantSelected"
                  @click="getCreditList"
                >
                  {{ $t('frontend.menu.credit') }}
                  <ion-badge
                    v-if="$store.state.allCustomerCredit.length > 0"
                    slot="end"
                    color="secondary"
                    >{{ $store.state.allCustomerCredit.length }}</ion-badge
                  >
                </ion-item>
                <ion-item
                  class="clickable"
                  v-if="
                    restaurantSelected &&
                    hasAds() &&
                    $store.state.configuration.viewDigitalSigned
                  "
                  @click="goAds()"
                >
                  {{ $t('frontend.menu.ads') }}</ion-item
                >
                <ion-item
                  class="clickable"
                  @click="trackingPackage()"
                  v-if="restaurantSelected"
                >
                {{ $t('frontend.menu.trackYourPackage') }}
                </ion-item>
                <ion-item
                  class="clickable"
                  @click="goToJournal()"
                  v-if="restaurantSelected"
                >
                  {{ $t('frontend.menu.foodAndMoodJournal') }}
                </ion-item>
                <ion-item
                  class="clickable"
                  v-if="
                    restaurantSelected &&
                    $store.state.restaurantActive.RestaurantBussines
                  "
                  @click="logging('', '', true)"
                >
                  {{ $t('frontend.menu.register') }}</ion-item
                >
                <ion-item
                  class="clickable"
                  v-if="
                    restaurantSelected &&
                    $store.state.restaurantActive.RestaurantBussines
                  "
                  @click="chat(false)"
                >
                  {{ $t('frontend.menu.chatImenu') }}</ion-item
                >
                <ion-item>
                  <ion-title class="menu-col-12" style="text-align: center"
                    ><a
                      href="https://imenuapps.com/"
                      target="_blank"
                      style="text-decoration: none"
                      >iMenuApps®</a
                    ><br />
                    <ion-note>v{{ appVersion }}</ion-note> |
                    <ion-note>{{ devUserTimezone }}</ion-note>
                  </ion-title>
                </ion-item>
              </ion-card>
              <!--FIN BUSINESS HAMBURGUER MENU-->
            </ion-content>
          </ion-content>
        </ion-menu>

        <ion-menu contentId="my-content" side="end">
          <ion-header>
            <ion-toolbar color="secondary">
              <ion-title v-if="!getAuthenticated">{{
                $t('backoffice.options.menuTitle')
              }}</ion-title>
              <div
                v-if="getUserAuthenticated"
                style="
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-evenly;
                "
              >
                <ion-avatar
                  style="width: 40px; height: 40px; float: left"
                  @click="editUserBack(getUserAuthenticated._id)"
                >
                  <img
                    v-if="getUserAuthenticated.ImageUrl != ''"
                    :src="getUserAuthenticated.ImageUrl"
                  />
                  <img v-else :src="require('./backoffice/assets/user.png')" />
                </ion-avatar>

                <div @click="editUserBack(getUserAuthenticated._id)">
                  <p style="font-size: 12px; margin-bottom: auto">
                    {{ getUserAuthenticated.FirstName }}
                    {{ getUserAuthenticated.LastName }}
                  </p>
                  <p style="font-size: 10px; margin-top: auto">
                    {{ getUserAuthenticated.Email }}
                  </p>
                </div>
                <Language />
              </div>
            </ion-toolbar>
          </ion-header>
          <ion-content>
            <ion-item> </ion-item>

            <ion-content v-if="!getAuthenticated" :key="newRestaurantKey">
              <Login :createdRestaurantEmail="createdRestaurantEmail" />
            </ion-content>

            <br />

            <router-link
              to="/controlPanel"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item v-if="accessToControlPanel()" @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="mdi:tools"
                  data-inline="false"
                ></span
                >Control Panel</ion-item
              ></router-link
            >

            <!-- <router-link to="/assistance" style="cursor: pointer; text-decoration: none; color: black;"><ion-item v-if="accessToControlPanel()" @click="closeEnd()"><span class="iconify" data-icon="mdi:account-clock" data-inline="false"></span>ClockIn-ClockOut</ion-item></router-link> -->

            <!-- SALES -->
            <ion-item
              color="light"
              @click="
                session == 'orderstickets'
                  ? (session = '')
                  : (session = 'orderstickets')
              "
              style="cursor: pointer"
              v-if="vClockin && hasPermission('canViewOrder')"
            >
              <span class="iconify" data-icon="dashicons:money-alt"></span>
              SALES</ion-item
            >
            <router-link
              to="/order"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewOrder') && session == 'orderstickets'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="mdi:order-bool-descending"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.viewOrders') }}</ion-item
              ></router-link
            >
            <router-link
              to="/reservationbackoffice"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewReservation') &&
                  session == 'orderstickets'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="ic:baseline-access-time"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageReservation') }}</ion-item
              ></router-link
            >
            <router-link
              :to="{ name: 'Quote', params: { forInvoice: false } }"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewOrder') && session == 'orderstickets'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="mdi:order-bool-descending"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.viewQuotes') }}</ion-item
              ></router-link
            >
            <router-link
              :to="{ name: 'Invoice', params: { forInvoice: true } }"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewOrder') && session == 'orderstickets'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="mdi:order-bool-descending"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.viewInvoice') }}</ion-item
              ></router-link
            >

            <!-- ADMIN -->
            <ion-item
              color="light"
              @click="
                session == 'restaurantadministration'
                  ? (session = '')
                  : (session = 'restaurantadministration')
              "
              style="cursor: pointer"
              v-if="
                vClockin &&
                (hasPermission('canViewMenu') ||
                  hasPermission('canViewCategory') ||
                  hasPermission('canViewProduct'))
              "
            >
              <span
                class="iconify"
                data-icon="dashicons:admin-appearance"
              ></span>
              {{ $t('backoffice.menu.restaurantAdministration') }}</ion-item
            >
            <router-link
              to="/menu"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewMenu') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="bx:bx-food-menu"
                  color="primary"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageMenus') }}</ion-item
              ></router-link
            >
            <router-link
              to="/category"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewCategory') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="carbon:category-new-each"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageCategories') }}</ion-item
              ></router-link
            >
            <router-link
              to="/product"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewProduct') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="dashicons:products"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageProducts') }}</ion-item
              ></router-link
            >
            <router-link
              to="/product/service"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewProduct') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="dashicons:businessman"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageServices') }}</ion-item
              ></router-link
            >
            <router-link
              to="/otherCharge"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewOtherCharge') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="si-glyph:money-bag"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageOtherCharges') }}</ion-item
              ></router-link
            >
            <router-link
              to="/specialsprice"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewSpecialPrices') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="ic:baseline-price-check"
                  data-inline="false"
                ></span
                >{{ $t('frontend.specialsPrice.titles') }}</ion-item
              ></router-link
            >
            <br v-if="session == 'restaurantadministration'" />
            <a
              @click="showStaffList()"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewUser') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  color="primary"
                  data-icon="simple-icons:codechef"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageUsers') }}</ion-item
              ></a
            >
            <a
              @click="showDriverList()"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewDriver') &&
                  session == 'restaurantadministration'
                "
                ><span
                  class="iconify"
                  data-icon="mdi:car-child-seat"
                  data-inline="false"
                ></span
                >Drivers</ion-item
              ></a
            >
            <router-link
              to="/role"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewRole') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="ls:cookpad"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageRoles') }}</ion-item
              ></router-link
            >
            <router-link
              to="/worksheet"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewWorkSheet') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="bx:bx-spreadsheet"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageWorkSheet') }}</ion-item
              ></router-link
            >
            <router-link
              to="/occupation"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewOccupation') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="tabler:tools-kitchen"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageOccupation') }}</ion-item
              ></router-link
            >
            <router-link
              to="/customer"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewCustomer') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="raphael:people"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageCustomers') }}</ion-item
              ></router-link
            >
            <router-link
              to="/credit"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewCredit') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="mdi:credit-card-plus"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageCredits') }}</ion-item
              ></router-link
            >
            <br v-if="session == 'restaurantadministration'" />
            <router-link
              to="/table"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewTable') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="vs:table-o"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageTables') }}</ion-item
              ></router-link
            >
            <router-link
              to="/tax"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewTax') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="heroicons-outline:receipt-tax"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageTaxes') }}</ion-item
              ></router-link
            >
            <router-link
              to="/shipping"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewShipping') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="la:truck-pickup-solid"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.manageShippings') }}</ion-item
              ></router-link
            >
            <br v-if="session == 'restaurantadministration'" />
            <router-link
              to="/subscriber"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canViewSuscriptor') &&
                  session == 'restaurantadministration'
                "
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="mdi:email-newsletter"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.titles.suscriptors') }}</ion-item
              ></router-link
            >
            <ion-item
              v-if="
                hasPermission('canViewSuscriptor') &&
                session == 'restaurantadministration'
              "
              @click="chat(true)"
            >
              <ion-icon
                style="height: 30px; width: 50px; margin-top: 3px; color: black"
                :src="require('@/backoffice/assets/icons/mail.svg')"
                name="send"
              ></ion-icon>
              {{ $t('frontend.menu.chat') }}
            </ion-item>
            <ion-item
              v-if="
                hasPermission('canViewSuscriptor') &&
                session == 'restaurantadministration'
              "
              @click="eMarkeeting"
            >
              <ion-icon
                style="height: 30px; width: 50px; margin-top: 3px; color: black"
                :src="require('@/backoffice/assets/icons/megaphone.svg')"
                name="send"
              ></ion-icon>
              {{ $t('backoffice.menu.marketing') }}
            </ion-item>
            <!-- Report -->
            <ion-item
              color="light"
              @click="
                session == 'reports' ? (session = '') : (session = 'reports')
              "
              style="cursor: pointer"
              v-if="vClockin"
            >
              <span
                class="iconify"
                data-icon="icon-park-outline:table-report"
              ></span>
              Reports
            </ion-item>
            <router-link
              to="/payment"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="hasPermission('canViewPayments') && session == 'reports'"
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="ic:twotone-attach-money"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.managePyments') }}</ion-item
              ></router-link
            >

            <!-- CONfig -->
            <ion-item
              color="light"
              @click="
                session == 'settings' ? (session = '') : (session = 'settings')
              "
              style="cursor: pointer"
              v-if="
                vClockin &&
                (hasPermission('canChangeSetting') ||
                  hasPermission('canSuperUser'))
              "
            >
              <span
                class="iconify"
                data-icon="eos-icons:configuration-file"
              ></span>
              <!---{{ $t("backoffice.menu.settings") }} -->
              Admin Settings
            </ion-item>
            <!-- <router-link to="/aboutDataSettings" style="cursor: pointer; text-decoration: none; color: black;"><ion-item v-if="hasPermission('canChangeSetting') && settings" @click="closeEnd()"><span class="iconify" data-icon="grommet-icons:restaurant" data-inline="false"></span>{{ $t('backoffice.options.manageAboutSettings') }}</ion-item></router-link> -->
            <!-- <a @click="manageBasicSettings()" style="cursor: pointer; text-decoration: none; color: black;"><ion-item v-if="hasPermission('canChangeSetting') && settings"><span class="iconify" data-icon="clarity:settings-line" data-inline="false"></span>{{ $t('backoffice.options.manageBasicSettings') }}</ion-item></a> -->
            <a
              @click="manageFunSettings()"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="
                  hasPermission('canChangeSetting') && session == 'settings'
                "
                ><span
                  class="iconify"
                  data-icon="clarity:file-settings-line"
                  data-inline="false"
                ></span>
                Functionality Setting<!--{{ $t("backoffice.menu.settings") }} -->
              </ion-item></a
            >
            <!-- <a @click="manageKeySettings()" style="cursor: pointer; text-decoration: none; color: black;"><ion-item v-if="hasPermission('canChangeSetting') && settings"><span class="iconify" data-icon="clarity:network-settings-solid" data-inline="false"></span>{{ $t('backoffice.options.manageKeySettings') }}</ion-item></a> -->
            <router-link
              to="/support"
              style="cursor: pointer; text-decoration: none; color: black"
              ><ion-item
                v-if="hasPermission('canSuperUser') && session == 'settings'"
                @click="closeEnd()"
                ><span
                  class="iconify"
                  data-icon="mdi:account-supervisor"
                  data-inline="false"
                ></span
                >{{ $t('backoffice.options.supportSettings') }}</ion-item
              ></router-link
            >
            <!-- <ion-item v-if="hasPermission('canViewVariantGroup')" @click="closeEnd()"><router-link to="/variantGroup" style="cursor: pointer; text-decoration: none; color: black;">{{ $t('backoffice.options.manageVariantGroup') }}</router-link></ion-item> -->
            <!-- <ion-item><router-link to="/demo" style="cursor: pointer; text-decoration: none; color: black;">Demo</router-link></ion-item> -->
            <ion-item v-if="$store.state.user && session == 'settings'">
              <span class="iconify" data-icon="ph:device-mobile-light"></span>
              <DeviceList />
            </ion-item>

            <div v-if="$store.state.user"><vue-clock></vue-clock></div>
            <ion-item
              v-if="getAuthenticated"
              @click="logOut"
              style="cursor: pointer"
              ><span
                class="iconify"
                data-icon="mdi:logout-variant"
                data-inline="false"
              ></span
              >{{ $t('backoffice.options.logout') }}</ion-item
            >
          </ion-content>
        </ion-menu>

        <!--Main in div control the access to backoffice from on page and the hamburger from page-->
        <div main id="my-content" class="no-print">
          <ion-header>
            <ion-toolbar
              v-if="isIos"
              style="height: 30px; margin-bottom: -1px"
              class="top-navbar"
              color="primary"
              :key="restaurantSelectedId"
            ></ion-toolbar>
            <!-- <ion-toolbar class="top-navbar" color="primary" :key="restaurantSelectedId">

              <div v-if="!newRestaurant" style="display:flex;justify-content: space-between;align-items: center;"> 
                <ion-buttons 
                v-if="this.$store.state.restaurantActive.restaurantName && !this.getAuthenticated && this.restaurantSelectedId!==''"
                slot="start">
                  <ion-icon @click="goToHome" name="home" style="font-size: 30px;"></ion-icon>
                  <ion-icon @click="openStart" name="menu" style="font-size: 30px; margin-top: 7px;"></ion-icon>
                </ion-buttons>
                
                <ion-title class="toolbar-title"
                v-if="this.$store.state.restaurantActive.restaurantName && !this.getAuthenticated && this.restaurantSelectedId!==''"
                >
                {{$store.state.restaurantActive.restaurantName}}
                </ion-title>

                <div v-if="userLoginRestaurant" > 
                  <div v-if="userLoginRestaurant.length > 0 && $store.state.user !== null" :key="userLoginRestaurantId"> 
                      <ion-select interface="popover" :ok-text="$t('backoffice.form.messages.buttons.ok')" :cancel-text="$t('backoffice.form.messages.buttons.dismiss')" style="padding: 0"
                      @ionChange="changeStaffRestaurant($event.target.value)" v-bind:value="userLoginRestaurantId">
                          <ion-select-option v-for="restaurant in userLoginRestaurant" v-bind:key="restaurant._id" v-bind:value="restaurant._id" >{{restaurant.Name}}</ion-select-option>
                      </ion-select>
                  </div>
                </div>

                <ion-buttons slot="end">

                  <div :key="keyUserLogin" style="margin-top: 4px;float: right;" v-if="!customerName  && !getAuthenticated" @click="logging('','', false)">
                    <span class="iconify" data-icon="ph:user-circle" data-inline="false"></span>
                  </div>

                  <div :key="keyUserLogin+'L'" style="margin-top: 4px;float: right;height: 40px; width: 46px; padding-top: 3px;" v-if="customerName " @click="showEditUser=!showEditUser">
                    <span class="iconify" data-icon="ph:user-circle-fill" data-inline="false"></span>
                  </div>

                  <ion-icon
                v-if="!(this.$store.state.restaurantActive.restaurantName && !this.getAuthenticated && this.restaurantSelectedId!=='')"
                   @click="openEnd" name="settings" class="" style="font-size: 30px;"></ion-icon>
                </ion-buttons>
              </div>

            </ion-toolbar> -->

            <ion-toolbar color="primary" :key="restaurantSelectedId">
              <div
                v-if="!newRestaurant"
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <ion-buttons
                  style="padding-left: 15px"
                  v-if="
                    this.$store.state.restaurantActive.restaurantName &&
                    !this.getAuthenticated &&
                    this.restaurantSelectedId !== ''
                  "
                  slot="start"
                >
                  <ion-icon
                    @click="goToHome"
                    name="home"
                    style="font-size: 30px; padding-right: 20px"
                  ></ion-icon>
                  <ion-icon
                    @click="openStart"
                    name="menu"
                    style="font-size: 30px; margin-top: 7px"
                  ></ion-icon>
                </ion-buttons>
                <ion-buttons
                  style="padding-left: 15px"
                  v-if="this.getAuthenticated"
                  slot="start"
                >
                  <!--<ion-icon name="spacer" style="font-size: 30px; padding-right: 20px"></ion-icon>-->
                </ion-buttons>
                <ion-buttons
                  style="padding-left: 0"
                  v-if="
                    !this.getAuthenticated &&
                    !this.$store.state.restaurantActive.restaurantName
                  "
                  slot="start"
                >
                  <ion-menu-button></ion-menu-button>
                </ion-buttons>
                <!-- <ion-buttons style="padding-left: 15px" v-else slot="start">
                  <ion-icon
                    name="spacer"
                    style="font-size: 30px; padding-right: 20px"
                  ></ion-icon>
                </ion-buttons> -->
                <!-- <ion-buttons slot="start">
                  <ion-menu-button></ion-menu-button>
                </ion-buttons> -->
                <!-- <ion-icon @click="openStart" name="menu" class="menu-col-2" :style="getAuthenticated? 'opacity: 0;': 'opacity: 1;float: left;font-size: 30px;'" ></ion-icon>  -->
                <div
                  id="select-option"
                  v-if="
                    userLoginRestaurant.length > 0 && $store.state.user !== null
                  "
                  :key="userLoginRestaurantId"
                >
                  <ion-select
                    interface="alert"
                    :ok-text="$t('backoffice.form.messages.buttons.ok')"
                    :cancel-text="
                      $t('backoffice.form.messages.buttons.dismiss')
                    "
                    style="padding: 0"
                    @ionChange="changeStaffRestaurant($event.target.value)"
                    v-bind:value="userLoginRestaurantId"
                  >
                    <ion-select-option
                      v-for="restaurant in sortedRestaurants"
                      v-bind:key="restaurant._id"
                      v-bind:value="restaurant._id"
                    >
                      {{ restaurant.Name }}
                    </ion-select-option>
                  </ion-select>
                </div>

                <!-- <div class="center" v-if="userLoginRestaurant">
                  <ion-title id="title" v-if="$store.state.restaurantActive.restaurantName &&
                  !getAuthenticated &&
                  restaurantSelectedId !== ''
                  " class="title-ios"></ion-title>
                                <div id="select-option" v-if="userLoginRestaurant.length > 0 &&
                  $store.state.user !== null
                  " :key="userLoginRestaurantId">
                                  <ion-select interface="alert" :ok-text="$t('backoffice.form.messages.buttons.ok')" :cancel-text="$t('backoffice.form.messages.buttons.dismiss')
                  " style="padding: 0" @ionChange="changeStaffRestaurant($event.target.value)"
                      v-bind:value="userLoginRestaurantId">
                      <ion-select-option v-for="restaurant in userLoginRestaurant" v-bind:key="restaurant._id"
                        v-bind:value="restaurant._id">{{ restaurant.Name }}</ion-select-option>
                    </ion-select>
                  </div>
                </div> -->

                <ion-buttons style="padding-right: 10px" slot="end">
                  <ion-icon
                    :key="keyUserLogin"
                    v-if="!!getAuthenticated"
                    @click="openEnd"
                    name="settings"
                    class="settings"
                  ></ion-icon>
                  <!-- <ion-icon
                    v-if="
                      !(
                        this.$store.state.restaurantActive.restaurantName &&
                        !this.getAuthenticated &&
                        this.restaurantSelectedId !== ''
                      )
                    "
                    @click="openEnd"
                    name="settings"
                    class=""
                    style="font-size: 30px"
                  ></ion-icon> -->

                  <!--We comment code to hidde old button log with icon-->
                  <!-- <div
                    :key="keyUserLogin"
                    style="margin-top: 4px; float: right"
                    v-if="!customerName  && !getAuthenticated"
                    @click="logging('', '', false)"
                  >
                    <span
                      class="iconify"
                      data-icon="ph:user-circle"
                      data-inline="false"
                    ></span>
                  </div> -->
                  <!--We add code to create new button log with log-->
                  <!-- <ion-chip
                    class="button-log"
                    outline
                    :key="keyUserLogin"
                    v-if="!customerName  && !getAuthenticated"
                    @click="logging('', '', false)"
                  >
                    <ion-label>{{ $t("frontend.menu.log") }}</ion-label>
                  </ion-chip> -->
                  <ion-button
                    ref="loginButton"
                    shape="round"
                    class="button-log"
                    outline
                    :key="keyUserLogin"
                    v-if="!customerName && !getAuthenticated"
                    @click="showLogins = !showLogins"
                  >
                    <span>{{ $t('frontend.menu.signIn') }}</span>
                  </ion-button>
                  <div
                    :key="keyUserLogin + 'L'"
                    style="
                      margin-top: 4px;
                      float: right;
                      height: 40px;
                      width: 46px;
                      padding-top: 3px;
                    "
                    v-if="customerName"
                    @click="showEditUser = !showEditUser"
                  >
                    <span
                      class="iconify"
                      data-icon="ph:user-circle-fill"
                      data-inline="false"
                    ></span>
                  </div>
                </ion-buttons>
              </div>
            </ion-toolbar>
          </ion-header>
          <ion-card
            ref="loginMenu"
            v-if="showLogins && !customerName && !getAuthenticated"
            style="position: absolute; right: 35px; margin: 0"
          >
            <ion-item>
              <ion-chip outline @click="logging('', '', false)">
                <span>{{ $t('frontend.menu.membersLogin') }}</span>
              </ion-chip>
            </ion-item>
            <ion-item>
              <ion-chip outline @click="openEnd">
                <span>{{ $t('frontend.menu.merchantsLogin') }}</span>
              </ion-chip>
            </ion-item>
          </ion-card>

          <ion-card
            v-if="showEditUser"
            style="position: absolute; right: 35px; margin: 0"
          >
            <ion-chip
              @click="
                restaurantSelected ? goMyAccount() : logging(email, '', false),
                  (showEditUser = false)
              "
              style="padding: 0"
              v-tooltip="{
                content: $t('frontend.menu.editProfile'),
                placement: 'bottom',
                offset: '0, 10'
              }"
            >
              <span
                class="iconify"
                data-icon="fa-solid:user-edit"
                data-inline="false"
              ></span>
            </ion-chip>
            <ion-chip
              @click="cerrarSesion(), (showEditUser = false)"
              style="padding: 0"
              v-tooltip="{
                content: $t('frontend.menu.logout'),
                placement: 'bottom',
                offset: '0, 10'
              }"
            >
              <span
                class="iconify"
                data-icon="ic:twotone-logout"
                data-inline="false"
              ></span>
            </ion-chip>
          </ion-card>
          <ion-content></ion-content>
        </div>
      </div>
      <ion-menu-controller></ion-menu-controller>
    </div>

    <ion-content id="app-ionic-vue">
      <ion-loading
        v-if="spinner"
        showBackdrop="false"
        cssClass="my-custom-class"
        :message="$t('frontend.tooltips.loadRestaurant')"
      >
      </ion-loading>

      <div v-if="!restaurantSelected && !getAuthenticated && !newRestaurant">
        <ion-toolbar>
          <ion-label
            style="
              color: #00d181;
              font-size: 20px;
              padding: 5px 8px 0 8px !important;
            "
          >
            <span v-html="motivationMessage"></span>
          </ion-label>
          <h5 style="margin-bottom: 0; padding-bottom: 0">
            {{ $t('frontend.menu.restaurant') }}
          </h5>
          <ion-select
            interface="alert"
            icon="add"
            :ok-text="$t('backoffice.form.messages.buttons.ok')"
            :cancel-text="$t('backoffice.form.messages.buttons.dismiss')"
            :placeholder="$t('frontend.createNew.select')"
            :value="businessType"
            @ionChange="businessType = $event.target.value"
          >
            <ion-select-option
              v-for="res in businessTypeList"
              :key="res"
              :value="res"
              >{{ res }}</ion-select-option
            >
          </ion-select>

          <ion-searchbar
            @ionClear="handleInput('')"
            @input="handleInput($event.target.value)"
            :placeholder="$t('frontend.home.search')"
          >
          </ion-searchbar>
        </ion-toolbar>

        <ion-refresher
          style="position: relative"
          slot="fixed"
          @ionRefresh="doRefresh($event)"
        >
          <ion-refresher-content
            refreshing-spinner="crescent"
          ></ion-refresher-content>
        </ion-refresher>

        <div v-if="filterRestaurantSelect.length < 1">No business found</div>
        <div v-if="filterRestaurantSelect.length > 0">
          <div
            v-for="res in filterRestaurantSelect"
            :key="res._id"
            v-show="
              businessType === 'All Business Types'
                ? 1
                : businessType === res.BusinessType
                  ? 1
                  : 0
            "
            class="menu-col-12"
          >
            <ion-list style="cursor: pointer">
              <ion-item @click="changeRestaurant(res._id)">
                <div class="menu-col-2" style="text-align: center">
                  <img
                    :src="res.ImageUrl"
                    style="max-height: 60px; padding: 5px"
                  />
                </div>
                <ion-label class="ion-text-wrap">
                  <h3>
                    {{ res.Name }}<br />
                    <star-rating
                      v-if="configuration.viewRating"
                      v-bind:rounded-corners="false"
                      v-bind:increment="0.1"
                      v-bind:star-size="20"
                      v-bind:readonly="true"
                      v-bind:rating="totalRating(res)"
                    >
                    </star-rating
                    ><br />
                    <!-- <span>{{$t('frontend.menu.catering')}}</span> -->
                    <ion-note>{{ res.Address }}</ion-note>
                  </h3>
                </ion-label>
                <ion-label
                  class="ion-text-wrap"
                  v-if="res.RestaurantDaysAndTime"
                  style="text-align: right"
                >
                  <p>{{ restaurantSchedule(res) }}</p>
                  <p
                    v-if="restaurantOpen(res) === true"
                    style="color: rgb(9 200 70)"
                  >
                    {{ $t('frontend.reservation.open') }}
                    <span
                      class="iconify"
                      data-icon="et:clock"
                      data-inline="false"
                      style="
                        width: 15px;
                        height: 15px;
                        color: rgb(9 200 70);
                        margin: 0;
                      "
                    ></span>
                  </p>
                  <p
                    v-if="restaurantOpen(res) === false"
                    style="color: #f10b28eb"
                  >
                    {{ $t('frontend.reservation.close') }}
                    <!-- <span class="iconify" data-icon="et:clock" data-inline="false"  style="width: 15px; height: 15px;color: #f10b28eb; margin: 0;"></span> -->
                  </p>
                </ion-label>
              </ion-item>
            </ion-list>
          </div>
        </div>
      </div>

      <router-view style="padding: 0 12px" :key="$route.path" />

      <new-resturant
        v-if="newRestaurant && showNew"
        :parent="this"
      ></new-resturant>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed" class="no-print">
        <ion-fab-button @click="showScannerModal"
          ><ion-icon name="ios-qr-scanner" color="white"></ion-icon
        ></ion-fab-button>
      </ion-fab>
    </ion-content>

    <modal
      name="scanner-modal"
      width="80%"
      height="80%"
      style="max-width: 300px"
    >
      <ion-toolbar>
        <ion-title>Scanner</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="$modal.hide('scanner-modal')">❌</ion-button>
        </ion-buttons>
      </ion-toolbar>

      <div class="ion-padding" style="height: 90%">
        <!--<qrcode-stream @decode="onDecode" @init="onInit" />-->
        <StreamBarcodeReader
          v-if="isScanning"
          @decode="onDecode"
        ></StreamBarcodeReader>

        <ion-content v-if="!isScanning">
          <div class="history">
            <ion-button @click="isScanning = !isScanning"
              >Scan Again</ion-button
            >
            <ion-list>
              <ion-item-sliding
                v-for="scannedItem in scannedItems"
                :key="scannedItem.id"
              >
                <ion-item>
                  <ion-label class="ion-text-wrap"
                    >{{ scannedItem.content }}<br />
                    <span class="subtitle">{{ scannedItem.created_at }}</span>
                  </ion-label>
                </ion-item>
                <ion-item-options side="end">
                  <ion-item-option @click="deleteScanned(scannedItem)"
                    >Delete</ion-item-option
                  >
                </ion-item-options>
              </ion-item-sliding>
            </ion-list>
          </div>
        </ion-content>
      </div>
    </modal>

    <ion-footer class="ion-no-border">
      <div
        style="
          width: 250px;
          height: 250px;
          margin: 0 auto;
          background: #262626;
          padding: 10px;
        "
        v-if="!getAuthenticated && restaurantSelectedId === '' && 0"
      >
        ADS
      </div>

      <ion-toolbar
        class="height-toolbar"
        v-if="
          !getAuthenticated &&
          restaurantSelectedId === '' &&
          signages.length > 0
        "
      >
        <ion-slides pager="true" :options="slideOpts" ref="mySlides">
          <ion-slide
            v-for="digitalSignage in signages"
            :key="digitalSignage._id"
          >
            <ion-card class="display-card">
              <ion-card-content class="display-content">
                <ion-grid>
                  <ion-row>
                    <ion-col
                      size="1"
                      class="slider-button-left"
                      @click="prevSlider"
                    >
                      <button>
                        <span
                          style="width: 20px"
                          class="iconify"
                          data-icon="ooui:previous-ltr"
                          data-inline="false"
                        ></span>
                      </button>
                    </ion-col>
                    <ion-col
                      :size="digitalSignage.ShowText ? 4 : 10"
                      class="content"
                      ><img :src="digitalSignage.ImageUrl" class="image-slide"
                    /></ion-col>
                    <ion-col
                      size="6"
                      class="content-2"
                      v-if="digitalSignage.ShowText"
                    >
                    <div v-html="digitalSignage.Title"></div>
                    <div v-html="digitalSignage.Subtitle"></div>
                    <div v-html="digitalSignage.Description"></div>
                      <!-- <ion-card-title class="titles">{{
                        digitalSignage.Title
                      }}</ion-card-title>
                      <ion-card-subtitle class="titles">{{
                        digitalSignage.Subtitle
                      }}</ion-card-subtitle>
                      <ion-label class="titles">{{
                        digitalSignage.Description
                      }}</ion-label> -->
                    </ion-col>
                    <ion-col
                      size="1"
                      class="slider-button-right"
                      @click="nextSlider"
                    >
                      <button>
                        <span
                          style="width: 20px"
                          class="iconify"
                          data-icon="ooui:previous-rtl"
                          data-inline="false"
                        ></span>
                      </button>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-slide>
        </ion-slides>
      </ion-toolbar>
    </ion-footer>

    <!--    <dynamic-footer></dynamic-footer>-->
  </ion-app>
</template>

<script src="https://unpkg.com/ionicons@5.0.0/dist/ionicons.js"></script>

<script>
import Language from './backoffice/views/Locale.vue'
import { Api } from './backoffice/api/api.js'
import { EventBus } from './frontend/event-bus'
import { QrcodeStream } from 'vue-qrcode-reader'
//import moment from "moment-timezone";
import { DateTime } from 'luxon'
import StarRating from 'vue-star-rating'
import IdleTracker from 'idle-tracker'
import { Plugins } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { qrScanner, scaleOutline } from 'ionicons/icons'
import { addIcons } from 'ionicons'
import Swal from 'sweetalert2'
import * as packageJson from '../package.json'
import { Utils } from '../src/backoffice/utils/utils.js'
import DynamicFooter from './frontend/components/DynamicFooter.vue'

import { StreamBarcodeReader } from 'vue-barcode-reader'
import { Commons } from '@/frontend/commons'

// import { Commons } from '../commons'

addIcons({
  'ios-qr-scanner': qrScanner.ios,
  'md-qr-scanner': qrScanner.md,
  'scale-outline': scaleOutline
})

export default {
  name: 'AppVue',

  created: async function () {
    this.isIos = this.$device.ios

    //Backoffice
    EventBus.$on('clockIn', async (value) => {
      this.vClockin = value
    })

    if (this.$route.query.new) {
      this.newRestaurant = true
      this.showNew = true
      return this.$router.push({
        name: 'NewBusiness',
        params: { parent: this }
      })
    }

    if (this.$route.query.unsubscribe) {
      this.newRestaurant = true
      return this.$router.push({
        name: 'Unsubscribe',
        params: { restaurantId: this.$route.query.unsubscribe, parent: this }
      })
    }

    if (this.$route.query.share && this.$route.query.rid) {
      this.productDetail(this.$route.query.share, this.$route.query.rid)
    }
    if (this.$route.query.menu) {
      this.showMenuRestaurant(this.$route.query.menu)
    }

    if (this.$route.query.curbside && this.$route.query.rid) {
      this.setCurbsideArrive(this.$route.query.curbside, this.$route.query.rid)
    }
    if (this.$route.query.acceptquote && this.$route.query.rid) {
      this.acceptQuote(this.$route.query.acceptquote, this.$route.query.rid)
    }

    if (this.$route.query.payinvoice && this.$route.query.rid) {
      this.payInvoice(this.$route.query.payinvoice, this.$route.query.rid)
    }

    if (this.$route.query.shareinvoice && this.$route.query.rid) {
      this.shareInvoice(this.$route.query.shareinvoice, this.$route.query.rid)
    }
    if (this.$route.query.approveWorksheet && this.$route.query.rid) {
      this.approveWorksheet(this.$route.query.sheetId, this.$route.query.rid)
    }

    await this.getAllRestaurant()

    if (this.$route.params.openEnd) {
      this.openEnd()
    }

    if (this.$route.params.url) {
      const urlRoute = this.$route.params.url
      const restByRouteIndex = this.allRestaurant.findIndex(
        (r) => r.Url === urlRoute
      )

      //console.log('JQD', this.$route.params.url, this.allRestaurant[restByRouteIndex]);
      if (restByRouteIndex === -1) {
        this.$router.push({ name: 'AppVue' })
        return this.alertNotRestaurantFound()
      } else {
        //console.log('JQD', 'restByRouteIndex is', restByRouteIndex);
        this.changeRestaurant(this.allRestaurant[restByRouteIndex]._id)
      }
    }

    EventBus.$on('updateCustomer', (value) => {
      if (value) this.customerUpdated()
    })
    EventBus.$on('startCustomerCounter', (value) => {
      if (value) this.timeCounterCustomer()
    })
    EventBus.$on('startFrontCounter', (value) => {
      if (value) this.timeCounterFrontend()
    })
    EventBus.$on('blockScreen', (value) => {
      if (value) this.timeCounterBack()
    })
    EventBus.$on('updateStoreKey', (value) => {
      if (value) this.storeKey++
    })
    EventBus.$on('updateRestaurantSelectedId', (value) => {
      if (value != '') this.changeRestaurant(value)
    })
    EventBus.$on('staffName', async (value) => {
      this.staffName = value
      this.$store.commit('setStaffName', value)
      if (value !== '' && this.$store.state.user)
        this.staffAllRestaurant = this.$store.state.user.AllRestaurant
      else this.staffAllRestaurant = []

      await this.getAllRestaurant()
    })
    EventBus.$on('clientHasId', (value) => {
      this.clientId = value
    })
    EventBus.$on('staffId', (value) => {
      this.staffId = value
      this.$store.commit('setStaffId', value)
    })

    EventBus.$on('openHome', (value) => {
      if (value === true) {
        return this.$router.push({ name: 'Home' })
      }
      // EventBus.$off('openHome');
    })

    EventBus.$on('updateAllOrders', (value) => {
      this.allOrders = value
      EventBus.$off('updateAllOrders')
    })

    EventBus.$on('changeRoute', async (value) => {
      if (value === 'reservation') {
        return this.$router.push({ name: 'Reservation' })
      }
      if (value === 'yourOrdersList') {
        return this.$router.push({ name: 'ListOrder' })
      }
      if (value === 'yourListCredit') {
        return this.$router.push({ name: 'ListCredit' })
      }
    })

    EventBus.$on('editInfoClient', (value) => {
      if (value && this.email != '') this.logging(this.email, '', false)
    })
    EventBus.$on('userRestaurant', (value) => {
      this.userLoginRestaurant = value
      this.userLoginRestaurantId = this.$store.state.user.RestaurantId
    })

    this.$router.beforeEach((to, from, next) => {
      if (to.name === 'App') {
        // this.restaurantSelected = false;
        // this.newRestaurant = false;
        // this.spinner = false;
        // this.restaurantSelectedId = '';
        next(true)
        this.$router.go(0)
      } else {
        next()
      }
    })
  },

  beforeDestroy: () => {
    document.removeEventListener('click', this.handleClickOutside)
    this.idleTrackerBack.end()
    this.idleTracker.end()
    this.idleTrackerRestartFront.end()
    clearInterval(this.autoSlideInterval)
  },

  watch: {
    '$i18n.locale': {
      immediate: true,
      handler(newLocale) {
        this.fullText = this.$t('frontend.app.fullText')
      }
    }
  },

  data() {
    return {
      showAboutImenu: false,
      showCloseIcon: false,
      showFullText: false,
      fullText: '',
      motivationMessage: '', // New property for dynamic message
      //Submenus

      isIos: false,
      session: '',
      deliveriesreservations: false,
      staffoccupationscustomers: false,
      marketing: false,
      settings: false,

      allRestaurant: [],
      restaurantSelected: false,
      restaurantSelectedId: '',
      filterRestaurantSelect: [],
      staffName: '',
      staffId: '',
      staffAllRestaurant: [],

      slideOpts: {
        speed: 400,
        slideShadows: true,
        initialSlide: 1,
        slidesPerView: 3
      },
      signages: [],
      autoSlideInterval: null,
      currentSlide: 0,

      modelName: 'Customer',
      //clientId: this.$store.state.customer._id || "",
      //customerName: this.$store.state.customer.Name || "",
      //phone: this.$store.state.customer.Phone || "",
      //email: this.$store.state.customer.EmailAddress || "",
      clientId: '',
      customerName: '',
      phone: '',
      email: '',
      marketingEmail: false,
      marketingPhone: false,

      prod: [],
      cart: [],
      order: {},

      spinner: false,
      spinnerKey: 0,

      products: [],
      categories: [],
      variants: [],
      taxesName: '',
      taxes: 1,
      shipping: 0,
      shippingName: '',
      allOrders: [],

      key: 0,
      rating: [],

      menuListSinCatering: [],
      categoryMenuListSinCatering: [],
      menuListConCatering: [],
      categoryMenuConCatering: [],
      viewCatering: false,
      viewRating: false,
      payForQuotation: false,
      viewReservation: false,
      viewCustomerReservation: false,
      viewOnTable: false,
      viewCurbside: false,
      hasReservationQuotation: false,
      restaurantActive: {},
      configuration: {},
      idleTracker: new IdleTracker({
        timeout: 600000, // Cierra sesion tras 10 min de inactividad. 600000
        onIdleCallback: () => {
          //console.log('JQD', 'onIdleCallback');

          if (!this.idleTracker.state.idle) return this.alertSesionExpired()
        },
        throttle: 500
      }),
      idleTrackerBack: new IdleTracker({
        timeout: 120000, // Cierra sesion tras 2 min de inactividad.
        onIdleCallback: () => {
          //console.log('JQD', 'idleTrackerBack');
          if (!this.idleTrackerBack.state.idle && !this.isBackLocked) {
            this.backgroundLockScreen()
          }
        },
        throttle: 500
      }),
      idleTrackerRestartFront: new IdleTracker({
        timeout: 2700000, //Reload from tras 45 min de inactividad. 600000
        onIdleCallback: () => {
          //console.log('JQD', 'idleTrackerRestartFront');
          if (!this.idleTrackerRestartFront.state.idle) {
            return this.changeRestaurant(this.restaurantSelectedId)
          }
        },
        throttle: 500
      }),
      isBackLocked: false,

      //Backoffice
      userLoginRestaurant: [],
      userLoginRestaurantId: -1,
      showLoginMember: false,
      showLogins: false,
      showEditUser: false,
      keyUserLogin: 0,
      //If true it will show the new bussiness cration option
      newRestaurant: false,
      showNew: false,
      //END
      createdRestaurantEmail: '',
      newRestaurantKey: 3,
      storeKey: 1,
      vClockin: false,
      restaurantS: null,
      businessTypeList: [
        'All Business Types',
        'Hospitality',
        'Reservations',
        'Service',
        'Entertainment',
        'Retail'
      ],
      businessType: 'All Business Types',
      isScanning: true,
      scannedItems: [],
      appVersion: packageJson.version
    }
  },
  components: {
    DynamicFooter,
    Language,
    QrcodeStream: QrcodeStream,
    StarRating,
    StreamBarcodeReader,
    QrModal: () =>
      import(
        /* webpackChunkName: ".QrPaymentModal" */ './frontend/components/QrPaymentModal'
      ),
    Login: () =>
      import(/* webpackChunkName: "Login" */ './backoffice/views/Login.vue'),
    NewResturant: () =>
      import(
        /* webpackChunkName: "NewResturant" */ './frontend/views/NewRestautant.vue'
      ),
    VueClock: () =>
      import(
        /* webpackChunkName: "ClockInOut" */ './backoffice/views/ClockInOut.vue'
      ),
    DeviceList: () =>
      import(
        /* webpackChunkName: "DeviceList" */ './backoffice/views/SetDeviceDataModal.vue'
      )
  },
  computed: {
    //This code order alphabeticly restaurnts
    sortedRestaurants() {
      return this.userLoginRestaurant.slice().sort((a, b) => {
        return a.Name.localeCompare(b.Name)
      })
    },
    truncatedText() {
      if (this.showFullText) {
        return this.fullText
      } else {
        const words = this.fullText.split(' ')
        if (words.length <= 60) {
          return this.fullText
        } else {
          const truncated = words.slice(0, 60).join(' ')
          return truncated + '...'
        }
      }
    },
    getAuthenticated: function () {
      return this.$store.state.authenticated
    },
    getUserAuthenticated: function () {
      return this.$store.state.user
    },
    devUserTimezone() {
      return Utils.getUserTimezoneStr()
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    this.signages = this.$store.state.digitalSignage
    this.fetchAllDigitalSignage()

    //new code skip slide automaticly
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide()
    }, 8000)
    // Set initial motivation message based on screen size
    this.updateMotivationMessage()
    window.addEventListener('resize', this.updateMotivationMessage)

    const self = this
    setTimeout(function () {
      if (self.$route.query && self.$route.query.showLoginMember) {
        self.showLoginMember = true
        self.logging('', '', false)
      }
    }, 300)
    // this.logging('', '', false);
    //this.allRestaurant = this.$store.state.allRestaurant;
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('resize', this.updateMotivationMessage)
  },
  methods: {
    //Hide the options of loggin when click outside
    handleClickOutside(event) {
      if (
        this.$refs.loginMenu &&
        !this.$refs.loginMenu.contains(event.target) &&
        !this.$refs.loginButton.contains(event.target)
      ) {
        this.showLogins = false
      } else if (
        this.$refs.loginMenuSide &&
        !this.$refs.loginMenuSide.contains(event.target) &&
        !this.$refs.loginButtonSide.contains(event.target)
      ) {
        this.showLoginMember = false
      }
    },
    nextSlider() {
      this.$refs.mySlides.slideNext() // Método para pasar al siguiente slide
      this.currentSlide = this.$refs.mySlides.getActiveIndex()
    },

    prevSlider() {
      this.$refs.mySlides.slidePrev() // Método para retroceder al slide anterior
      this.currentSlide = this.$refs.mySlides.getActiveIndex()
    },

    nextSlide() {
      // Verifica si $refs.mySlides está definido
      if (this.$refs.mySlides) {
        // Verifica si swiper está definido
        const swiper = this.$refs.mySlides.swiper
        if (swiper) {
          // Cambia al siguiente slide

          if (swiper.isEnd) {
            swiper.activeIndex = 0
          }
          swiper.slideNext()
        } else {
          //console.error('La propiedad swiper no está definida en $refs.mySlides.');
        }
      } else {
        //console.error('El ref mySlides no está definido.');
      }
    },

    updateMotivationMessage() {
      const width = window.innerWidth
      if (width <= 768) {
        // Assuming 768px is the breakpoint for mobile
        this.motivationMessage = this.$t('frontend.menu.motivationMobile')
      } else {
        this.motivationMessage = this.$t('frontend.menu.motivation')
      }
    },

    async fetchAllDigitalSignage() {
      try {
        const response = await Api.fetchAlls('digitalsignage')

        if (response.data && Array.isArray(response.data)) {
          this.signages = response.data
        } else {
          console.error('Invalid response format:', response)
        }
      } catch (error) {
        console.error('Error fetching Digital Signage data:', error)
      }
    },

    toggleAboutImenu() {
      this.showAboutImenu = !this.showAboutImenu
      this.showCloseIcon = this.showAboutImenu
    },
    closeMenu() {
      const menu = document.querySelector('ion-menu')
      menu.close()
    },
    async changeStaffRestaurant(value) {
      this.session = ''
      this.userLoginRestaurantId = value
      this.spinner = true
      Api.setRestaurantId(this.userLoginRestaurantId)
      const response = await Api.fetchById('staff', this.$store.state.user._id)
      const { Commons } = require('./frontend/commons')
      await Commons.backOfficeLogin(response.data)

      this.$router.push({
        path: '/controlPanel'
      })
      this.spinner = false
    },

    async doRefresh(event) {
      await this.getAllRestaurant()
      event.target.complete()
    },

    handleInput(value) {
      const query = value.toLowerCase()
      requestAnimationFrame(() => {
        if (query == '') {
          this.filterRestaurantSelect = this.allRestaurant
        } else {
          let cat2 = this.allRestaurant.filter((p) =>
            p.Name.toLowerCase().includes(query)
          )
          if (cat2.length > 0) this.filterRestaurantSelect = cat2
          else this.filterRestaurantSelect = []
        }
      })
    },

    hasPermission(permission) {
      let res = false
      if (this.$store.state.authenticated) {
        let roles = this.$store.state.roles
        for (let index = 0; index < roles.length; index++) {
          switch (permission) {
            case 'canViewMenu':
              res = roles[index].canViewMenu
              break
            case 'canViewCategory':
              res = roles[index].canViewCategory
              break
            case 'canViewProduct':
              res = roles[index].canViewProduct
              break
            case 'canViewCustomer':
              res = roles[index].canViewCustomer
              break
            case 'canViewTable':
              res = roles[index].canViewTable
              break
            case 'canViewTax':
              res = roles[index].canViewTax
              break
            case 'canViewShipping':
              res = roles[index].canViewShipping
              break
            case 'canViewOtherCharge':
              res = roles[index].canViewOtherCharge
              break
            case 'canViewReservation':
              res = roles[index].canViewReservation
              break
            case 'canViewUser':
              res = roles[index].canViewUser
              break
            case 'canViewOccupation':
              res = roles[index].canViewOccupation
              break
            case 'canViewRole':
              res = roles[index].canViewRole
              break
            case 'canViewOrder':
              res = roles[index].canViewOrder
              break
            case 'canViewOrderForDelivery':
              res = roles[index].canViewOrderForDelivery
              break
            case 'canViewVariantGroup':
              res = roles[index].canViewVariantGroup
              break
            case 'canChangeSetting':
              res = roles[index].canChangeSetting
              break
            case 'canViewPayments':
              res = roles[index].canViewPayments
              break
            case 'canSuperUser':
              res = this.$store.state.user.IsSupport
              break
            case 'canViewDriver':
              res = roles[index].canViewDriver
              break
            case 'canViewSuscriptor':
              res = roles[index].canViewSuscriptor
              break
            case 'canIgnoreClockin':
              res = roles[index].canIgnoreClockin
              break
            case 'canViewSpecialPrices':
              res = roles[index].canViewSpecialPrices
              break
            case 'canViewCredit':
              res = roles[index].canViewCredit
              break
            case 'canViewWorkSheet':
              res = roles[index].canViewWorkSheet
              break
            default:
              break
          }
          if (res) {
            return res
          }
        }
      }
      return res
    },

    accessToControlPanel() {
      return (
        this.hasPermission('canViewMenu') ||
        this.hasPermission('canViewOrder') ||
        this.hasPermission('canViewCategory') ||
        this.hasPermission('canViewProduct') ||
        this.hasPermission('canViewCustomer') ||
        this.hasPermission('canViewTable') ||
        this.hasPermission('canViewTax') ||
        this.hasPermission('canViewShipping') ||
        this.hasPermission('canViewOtherCharge') ||
        this.hasPermission('canViewUser') ||
        this.hasPermission('canViewRole')
      )
    },
    showStaffList() {
      this.$router.push({
        name: 'User',
        params: {
          isForDriversSupervisor: false
        }
      })
    },
    showDriverList() {
      this.$router.push({
        name: 'Driver',
        params: {
          isForDriversSupervisor: true
        }
      })
    },
    manageBasicSettings() {
      this.closeEnd()
      const restaurantID = this.$store.state.user.RestaurantId
      if (restaurantID) {
        this.$router.push({
          name: 'BasicSettingForm',
          params: {
            settingId: restaurantID
          }
        })
      } else {
        this.$router.push({
          name: 'BasicSettingForm'
        })
      }
    },

    manageFunSettings() {
      this.closeEnd()
      Api.fetchAll('Setting')
        .then((response) => {
          let funSettings = []
          funSettings = response.data

          if (funSettings.length > 0) {
            this.$router.push({
              name: 'FunSettingForm',
              params: {
                settingId: funSettings[funSettings.length - 1]._id
              }
            })
          } else {
            this.$router.push({
              name: 'FunSettingForm'
            })
          }
        })
        .catch((e) => {
          e
        })
    },

    manageKeySettings() {
      this.closeEnd()
      const restaurantID = this.$store.state.user.RestaurantId
      if (restaurantID) {
        this.$router.push({
          name: 'KeySettingForm',
          params: {
            settingId: restaurantID
          }
        })
      } else {
        this.$router.push({
          name: 'KeySettingForm'
        })
      }
    },

    backgroundLockScreen() {
      this.isBackLocked = true
      const LockModal = () =>
        import(
          /* webpackChunkName: "LockModal" */ './backoffice/views/LockScreen.vue'
        )
      return this.$ionic.modalController
        .create({
          component: LockModal,
          backdropDismiss: false,
          cssClass: 'my-custom-class',
          componentProps: {
            data: {
              content: false
            },
            propsData: {
              parent: this
            }
          }
        })
        .then((m) => m.present())
    },

    openStart() {
      document.querySelector('ion-menu-controller').open('start')
    },

    openEnd() {
      document.querySelector('ion-menu-controller').open('end')
    },

    closeEnd() {
      document.querySelector('ion-menu-controller').close('end')
      this.deliveriesreservations = false
      this.staffoccupationscustomers = false
      this.settings = false
    },

    closeStart() {
      if (document.querySelector('ion-menu-controller'))
        document.querySelector('ion-menu-controller').close('start')
    },

    logOut: async function () {
      this.closeEnd()
      this.vClockin = false
      const rest = Api.getRestaurant()
      Api.setRestaurantId('')
      this.$store.commit('setAuthentication', false)
      this.$store.commit('setUser', null)

      // Reset Styles
      var allStyles =
        ':root { -ion-color-primary: #3880ff; ' +
        '--ion-color-primary-rgb: 56,128,255; ' +
        '--ion-color-primary-contrast: #ffffff; ' +
        '--ion-color-primary-contrast-rgb: 255,255,255; ' +
        '--ion-color-primary-shade: #3171e0; ' +
        '--ion-color-primary-tint: #4c8dff; ' +
        '--ion-color-secondary: #3dc2ff; ' +
        '--ion-color-secondary-rgb: 61,194,255; ' +
        '--ion-color-secondary-contrast: #ffffff; ' +
        '--ion-color-secondary-contrast-rgb: 255,255,255; ' +
        '--ion-color-secondary-shade: #36abe0; ' +
        '--ion-color-secondary-tint: #50c8ff; ' +
        '--ion-color-tertiary: #5260ff; ' +
        '--ion-color-tertiary-rgb: 82,96,255; ' +
        '--ion-color-tertiary-contrast: #ffffff; ' +
        '--ion-color-tertiary-contrast-rgb: 255,255,255; ' +
        '--ion-color-tertiary-shade: #4854e0; ' +
        '--ion-color-tertiary-tint: #6370ff; ' +
        '--ion-color-success: #2dd36f; ' +
        '--ion-color-success-rgb: 45,211,111; ' +
        '--ion-color-success-contrast: #ffffff; ' +
        '--ion-color-success-contrast-rgb: 255,255,255; ' +
        '--ion-color-success-shade: #28ba62; ' +
        '--ion-color-success-tint: #42d77d; ' +
        '--ion-color-danger: #eb445a; ' +
        '--ion-color-danger-rgb: 235,68,90; ' +
        '--ion-color-danger-contrast: #ffffff; ' +
        '--ion-color-danger-contrast-rgb: 255,255,255; ' +
        '--ion-color-danger-shade: #cf3c4f; ' +
        '--ion-color-danger-tint: #ed576b; }'
      document.querySelector('style').innerHTML += allStyles

      this.$store.commit('setRoles', [])

      // Desvincular eventos.
      EventBus.$emit('clientHasId', '')
      EventBus.$emit('clientHasName', '')
      EventBus.$emit('clientHasPhone', '')
      EventBus.$emit('clientHasEmail', '')
      EventBus.$emit('staffName', '')
      EventBus.$emit('staffId', '')
      this.staffName = ''
      this.staffId = ''
      this.$store.commit('setStaffName', '')
      this.$store.commit('setStaffId', '')

      let onLine = rest != null ? rest.Online : false
      if (this.restaurantSelectedId && onLine) {
        this.changeRestaurant(this.restaurantSelectedId)
      } else {
        this.restaurantSelectedId = ''
        this.$router.push({ name: 'AppVue' })
      }
      this.isBackLocked = false
      this.idleTrackerBack.end()
    },

    getAllRestaurant: async function () {
      this.spinner = true

      try {
        const response = await Api.fetchAll('Restaurant')
        //console.log('JQD', 'getAllRestaurant', response);
        if (response.status === 200) {
          const resData = response.data
          this.spinner = false
          let allRest
          if (this.staffName !== '') {
            allRest = resData.filter((r) =>
              this.staffAllRestaurant.includes(r._id)
            )
          } else allRest = resData.filter((r) => r.Online === true)
          this.$store.commit(
            'setAllRestaurant',
            JSON.parse(JSON.stringify(allRest))
          )
          this.allRestaurant = this.$store.state.allRestaurant
          this.filterRestaurantSelect = this.$store.state.allRestaurant
        }
      } catch (error) {
        this.spinner = false
        return this.$ionic.alertController
          .create({
            cssClass: 'my-custom-class',
            header: '',
            message: error,
            buttons: [
              {
                text: this.$t('frontend.home.acept'),
                handler: () => {}
              }
            ]
          })
          .then((a) => a.present())
      }
    },

    changeRestaurant: async function (restaurantId) {
      //console.log('JQD', 'changeRestaurant', 'this.restaurantSelectedId :', this.restaurantSelectedId , 'restaurantId:', restaurantId);
      //console.log('JQD', 'changeRestaurant', this.$route.query);

      if (this.restaurantSelectedId == restaurantId) {
        //console.log('JQD', 'changeRestaurant cancelled');
        return
      }
      const { Commons } = require('./frontend/commons')

      this.$ionic.loadingController
        .create({
          cssClass: 'my-custom-class',
          message: this.$t('frontend.tooltips.loadRestaurant'),
          backdropDismiss: false
        })
        .then((loading) => {
          loading.present()
          setTimeout(async () => {
            try {
              this.restaurantSelected = true
              this.restaurantSelectedId = restaurantId
              this.closeStart()
              await Commons.changeRestaurant(restaurantId)
              loading.dismiss()
              this.$forceUpdate()

              //JQD:
              //TODO: manage this, as this forcely refresh to the about page
              if (this.$route.query && this.$route.query.orderId) {
                //console.log('JQD', 'Don\'t go to About Page, handle the order id');

                //reload with a new route
                //this is a it hackish, should be updated SOON!
                this.$router.push({
                  name: 'ReservationOrder',
                  params: {
                    url: this.$store.state.restaurantActive.restaurantUrl,
                    key: this.key,
                    orderId: this.$route.query.orderId
                  }
                })
              } else {
                //console.log('JQD', 'App will redirect to the About page');
                this.$router.push({
                  name: 'AboutFront',
                  params: {
                    url: this.$store.state.restaurantActive.restaurantUrl,
                    key: this.key
                  }
                })
              }
            } catch (error) {
              error
              //console.log('JQD', 'changeRestaurant', error);
              loading.dismiss()
            }
          })
        })
    },

    logging: function (update, newRoute, isCreating) {
      this.closeStart()
      this.keyUserLogin++
      const loginModal = () =>
        import(
          /* webpackChunkName: "loginModal" */ './frontend/components/selectOrderType'
        )
      return this.$ionic.modalController
        .create({
          component: loginModal,
          cssClass: 'my-custom-class1',
          componentProps: {
            data: {
              content: 'New Content'
            },
            propsData: {
              restaurantEmail:
                this.$store.state.restaurantActive.restaurantEmail,
              update: update,
              newRoute: newRoute,
              isCreate: isCreating
            }
          }
        })
        .then((m) => m.present())
    },

    eMarkeeting: function () {
      this.closeEnd()
      this.$router.push({ name: 'eMarkeeting', params: {} })
    },

    chat: function (isAdmin) {
      this.closeEnd()
      if (isAdmin) {
        return this.$router.push({
          name: 'Connections',
          params: {
            restaurantId: this.userLoginRestaurantId,
            isAdmin: isAdmin
          }
        })
      } else {
        if (this.ClientId != '' && this.customerName != '')
          return this.$router.push({
            name: 'Chat',
            params: {
              customerId: this.clientId,
              customerName: this.customerName,
              restaurantId: this.restaurantSelectedId,
              isAdmin: isAdmin
            }
          })
        else return this.logging('', 'Chat', false)
        // this.closeStart();
        // this.$router.push({ name: 'Chat', params: {isCatering: false, url: this.$store.state.restaurantActive.restaurantUrl} })
      }
    },

    goToHome: function () {
      this.$router.push({ name: 'App' })
      //this.$router.replace({ name: "App" });
    },

    goHome: function () {
      this.closeStart()
      this.$router.push({
        name: 'Home',
        params: {
          isCatering: false,
          url: this.$store.state.restaurantActive.restaurantUrl
        }
      })
    },

    goCatering: function () {
      this.closeStart()
      this.$router.push({
        name: 'Catering',
        params: {
          isCatering: true,
          url: this.$store.state.restaurantActive.restaurantUrl
        }
      })
    },

    goAbout: function () {
      this.closeStart()
      return this.$router
        .push({
          name: 'AboutFront',
          params: { url: this.$store.state.restaurantActive.restaurantUrl }
        })
        .catch(() => {})
    },

    goAds: function () {
      this.closeStart()
      return this.$router
        .push({
          name: 'Ads',
          params: { url: this.$store.state.restaurantActive.restaurantUrl }
        })
        .catch(() => {})
    },

    getReservation: async function () {
      this.closeStart()
      return this.$router.push({
        name: 'Reservation',
        params: { url: this.$store.state.restaurantActive.restaurantUrl }
      })
      //  if(this.ClientId !='' && this.customerName  !='') return this.$router.push({ name: 'Reservation' })
      //  else return this.logging('','reservation', false);
    },

    getOrderList: async function () {
      this.closeStart()
      if (this.ClientId != '' && this.customerName != '')
        return this.$router.push({
          name: 'ListOrder',
          params: {
            customerId: this.clientId,
            customerName: this.customerName
          }
        })
      else return this.logging('', 'yourOrdersList', false)
    },

    getCreditList: async function () {
      this.closeStart()
      if (this.ClientId != '' && this.customerName != '')
        return this.$router.push({ name: 'ListCredit' })
      else return this.logging('', 'yourListCredit', false)
    },

    hasAds() {
      let flag = false
      if (this.$store.state.configuration)
        if (this.$store.state.configuration.ads)
          if (this.$store.state.configuration.ads.length > 0) flag = true

      return flag
    },

    async showMenuRestaurant(restaurantId) {
      const { Commons } = require('./frontend/commons')
      this.spinner = true
      const response = await Api.fetchById('Restaurant', restaurantId)
      if (response.status === 200) {
        const resData = response.data
        const flag = resData.Online
        if (flag) {
          this.restaurantSelected = true
          this.restaurantSelectedId = restaurantId
          Api.setRestaurantId(restaurantId)
          await Commons.changeRestaurant(restaurantId)
          this.$router.push({
            name: 'Home',
            params: { url: this.$store.state.restaurantActive.restaurantUrl }
          })
        } else {
          this.alertRestaurantOffline()
          this.$router.push({ name: 'AppVue' })
        }
        this.spinner = false
      }
    },

    alertRestaurantOffline() {
      this.email = ''
      return this.$ionic.alertController
        .create({
          cssClass: 'my-custom-class',
          header: '',
          message: this.$t('frontend.tooltips.errorRestaurantOffline'),
          buttons: [
            {
              text: this.buttonAcept,
              handler: () => {}
            }
          ]
        })
        .then((a) => a.present())
    },

    async setCurbsideArrive(orderId, restaurantId) {
      try {
        this.spinner = true
        const { Commons } = require('./frontend/commons')
        this.restaurantSelected = true
        this.restaurantSelectedId = restaurantId
        Api.setRestaurantId(restaurantId)
        await Commons.changeRestaurant(restaurantId)
        this.$router.push({
          name: 'AboutFront',
          params: { url: this.$store.state.restaurantActive.restaurantUrl }
        })

        await Api.fetchById('Order', orderId)
          .then(async (response) => {
            if (response.status === 200) {
              response.data.CarArrived = true
              const response1 = await Api.putIn('Order', response.data)
              this.spinner = false
              if (response1.status === 200) {
                return this.$ionic.toastController
                  .create({
                    message: this.$t('frontend.tooltips.carArrivedInfo'),
                    position: 'middle',
                    color: 'success',
                    buttons: [
                      { text: 'Done', role: 'cancel', handler: () => {} }
                    ]
                  })
                  .then((a) => a.present())
              }
            }
          })
          .catch((e) => {
            e
            this.spinner = false
          })
      } catch (error) {
        this.spinner = false
        error
      }
    },

    async acceptQuote(orderId, restaurantId) {
      try {
        this.spinner = true
        const { Commons } = require('./frontend/commons')
        this.restaurantSelected = true
        this.restaurantSelectedId = restaurantId
        Api.setRestaurantId(restaurantId)
        await Commons.changeRestaurant(restaurantId)
        const response = await Api.fetchById('Order', orderId)
        if (response.status === 200) {
          if (response.data.State === 0) {
            if (response.data.isInvoice) {
              this.$router.push({
                name: 'AboutFront',
                params: {
                  url: this.$store.state.restaurantActive.restaurantUrl
                }
              })
              return this.quoteAccepted()
            }

            // const currentDate = moment
            //   .tz(moment.tz.guess())
            //   .format("YYYY-MM-DD HH:mm");
            // const quoteDate = moment(
            //   response.data.QouteInvoice.expirationQuote
            // ).format("YYYY-MM-DD HH:mm");
            const currentDate = DateTime.now()
              .setZone(DateTime.local().zoneName)
              .toFormat('yyyy-MM-dd HH:mm')
            const quoteDate = DateTime.fromISO(
              response.data.QouteInvoice.expirationQuote
            ).toFormat('yyyy-MM-dd HH:mm')
            if (currentDate > quoteDate) {
              this.$router.push({
                name: 'AboutFront',
                params: {
                  url: this.$store.state.restaurantActive.restaurantUrl
                }
              })
              return this.quoteExpired()
            }
            const forInvoice = response.data.isInvoice ? true : false
            this.spinner = false
            return this.$router.push({
              name: 'QuoteDetailAccept',
              params: { quoteToEdit: response.data, forInvoice: forInvoice }
            })
          } else {
            this.quoteInOtherState(response.data.State)
          }

          this.$router.push({
            name: 'AboutFront',
            params: { url: this.$store.state.restaurantActive.restaurantUrl }
          })
        }
        this.spinner = false
      } catch (error) {
        this.spinner = false
        error
      }
    },
    async payInvoice(orderId, restaurantId) {
      //console.log('JQD', 'payInvoice', orderId, restaurantId);
      try {
        this.spinner = true
        const { Commons } = require('./frontend/commons')
        this.restaurantSelected = true
        this.restaurantSelectedId = restaurantId
        Api.setRestaurantId(restaurantId)
        await Commons.changeRestaurant(restaurantId)
        const response = await Api.fetchById('Order', orderId)
        //console.log('JQD', 'payInvoice response', response);
        if (response.status === 200) {
          if (
            response.data?.State === 0 &&
            (response.data?.OrderType === 'Invoice' ||
              response.data?.QouteInvoice?.expirationInvoice)
          ) {
            //console.log('JQD', 'payInvoice response 200', response);
            // const currentDate = moment
            //   .tz(moment.tz.guess())
            //   .format("YYYY-MM-DD HH:mm");
            // const quoteDate = moment(
            //   response.data.QouteInvoice.expirationInvoice
            // ).format("YYYY-MM-DD HH:mm");
            //JQD: TO check
            const currentDate = DateTime.now()
              .setZone(DateTime.local().zoneName)
              .toFormat('yyyy-MM-dd HH:mm')
            const quoteDate = DateTime.fromISO(
              response.data.QouteInvoice.expirationInvoice
            ).toFormat('yyyy-MM-dd HH:mm')

            if (currentDate > quoteDate) {
              this.$router.push({
                name: 'AboutFront',
                params: {
                  url: this.$store.state.restaurantActive.restaurantUrl
                }
              })
            }

            const orderObj = JSON.parse(JSON.stringify(response.data))
            orderObj.isInvoice = false
            this.$store.commit('setOrder', orderObj)
            const guess = {
              _id: response.data.ClientId,
              Name: response.data.customerName,
              EmailAddress: response.data.CustomerEmail,
              Phone: response.data.CustomerPhone
            }
            this.$store.commit('setGuess', guess)
            const forInvoice = response.data.isInvoice ? true : false
            this.spinner = false
            return this.$router.push({
              name: 'InvoiceDetailAccept',
              params: { quoteToEdit: response.data, forInvoice: forInvoice }
            })
          } else {
            this.$router.push({
              name: 'AboutFront',
              params: { url: this.$store.state.restaurantActive.restaurantUrl }
            })
            Swal.fire({
              icon: 'info',
              title: this.$t('frontend.app.thisPay'),
              text: this.$t('frontend.app.thisPayDone'),
              backdrop: false
            })
            //this.quoteInOtherState(response.data.State);
          }

          this.$router.push({
            name: 'AboutFront',
            params: { url: this.$store.state.restaurantActive.restaurantUrl }
          })
        }
        this.spinner = false
      } catch (error) {
        this.spinner = false
        error
      }
    },

    async approveWorksheet(sheetId, restaurantId) {
      try {
        this.spinner = true
        this.restaurantSelected = true
        this.restaurantSelectedId = restaurantId
        await Api.setRestaurantId(restaurantId)
        await Commons.changeRestaurant(restaurantId)
        const response = await Api.fetchById('worksheet', this.id)
        const data = response.data
        if (data.RequestedWorkingDates && data.RequestedWorkingDates.length) {
          data.WorkingDates = data.RequestedWorkingDates
          data.RequestedWorkingDates = []
          data.changeRequested = false
          await Api.putIn('worksheet', data)
          this.spinner = false
          Swal.fire({
            title: this.$t('frontend.home.reqSuccess'),
            icon: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          })
          // return this.$router.push('/');
        }
      } catch (e) {
        console.log(e.toString())
      }
      this.spinner = false
    },

    async shareInvoice(orderId, restaurantId) {
      try {
        this.spinner = true
        const { Commons } = require('./frontend/commons')
        this.restaurantSelected = true
        this.restaurantSelectedId = restaurantId
        Api.setRestaurantId(restaurantId)
        await Commons.changeRestaurant(restaurantId)
        const response = await Api.fetchById('Order', orderId)
        if (response.status === 200) {
          const orderObj = JSON.parse(JSON.stringify(response.data))
          orderObj.isInvoice = false
          this.$store.commit('setOrder', orderObj)
          const guess = {
            _id: response.data.ClientId,
            Name: response.data.customerName,
            EmailAddress: response.data.CustomerEmail,
            Phone: response.data.CustomerPhone
          }
          this.$store.commit('setGuess', guess)
          const forInvoice = response.data.isInvoice ? true : false
          this.spinner = false
          return this.$router.push({
            name: 'InvoiceDetailAccept',
            params: { quoteToEdit: response.data, forInvoice: forInvoice }
          })
        }
        this.spinner = false
      } catch (error) {
        this.spinner = false
        error
      }
    },

    productDetail: async function (productId, restaurantId) {
      const { Commons } = require('./frontend/commons')
      this.spinner = true
      this.restaurantSelected = true
      this.restaurantSelectedId = restaurantId
      Api.setRestaurantId(restaurantId)
      await Commons.changeRestaurant(restaurantId)
      this.$router.push({
        name: 'AboutFront',
        params: { url: this.$store.state.restaurantActive.restaurantUrl }
      })
      this.spinner = true
      Api.fetchById('Product', productId)
        .then((response) => {
          this.spinner = false
          if (response.status === 200) {
            const pr = response.data
            var productVariant = []
            if (pr.VariantGroupId != '') {
              productVariant = this.variants.filter(
                (v) => v._id == pr.VariantGroupId
              )
            }
            const productModal = () =>
              import(
                /* webpackChunkName: "productModal" */ './frontend/components/ProductDetail'
              )
            return this.$ionic.modalController
              .create({
                component: productModal,
                cssClass: 'my-custom-class',
                componentProps: {
                  data: {
                    content: 'New Content'
                  },
                  propsData: {
                    productId: pr._id,
                    Name: pr.Name,
                    RetailPrice: parseFloat(pr.RetailPrice),
                    Description: pr.Description,
                    ImageUrl: pr.ImageUrl,
                    ProductCant: pr.ProductCant,
                    Note: pr.Note,
                    productVariant: productVariant,
                    aggregateCant: pr.AggregateCant || 0,
                    Aggregates: pr.Aggregates || [],
                    products: this.products,
                    orderType: this.orderType,
                    Ingredients: pr.Ingredients || [],
                    orderFromCatering: pr.fromCatering,
                    isService: pr.isService,
                    currency: this.$store.state.restaurantActive.currency
                  }
                }
              })
              .then((m) => m.present())
          }
        })
        .catch((e) => {
          e
          this.spinner = false
        })
    },

    async alertSesionExpired() {
      this.idleTracker.end()
      this.cerrarSesion()
      const modal = await this.$ionic.alertController.create({
        cssClass: 'my-custom-class',
        header: '',
        message: this.$t('frontend.app.sessionExpired'),
        buttons: [
          {
            text: this.$t('frontend.home.acept'),
            handler: () => {}
          }
        ]
      })
      return modal.present()
      // .then(a => a.present())
    },

    alertNotRestaurantFound() {
      return this.$ionic.alertController
        .create({
          cssClass: 'my-custom-class',
          header: '',
          message: this.$t('frontend.app.notRestaurantFound'),
          buttons: [
            {
              text: this.$t('frontend.home.acept'),
              handler: () => {}
            }
          ]
        })
        .then((a) => a.present())
    },

    quoteExpired() {
      this.spinner = false
      return this.$ionic.alertController
        .create({
          cssClass: 'my-custom-class',
          header: '',
          message: this.$t('frontend.payment.quoteExpired'),
          buttons: [
            {
              text: this.$t('frontend.home.acept'),
              handler: () => {}
            }
          ]
        })
        .then((a) => a.present())
    },

    quoteInOtherState(state) {
      const { Commons } = require('./frontend/commons')
      return this.$ionic.alertController
        .create({
          cssClass: 'my-custom-class',
          header: '',
          message: `${this.$t('frontend.payment.quoteChanged')} <strong> ${
            Commons.getState(state).name
          } </strong>`,
          buttons: [
            {
              text: this.$t('frontend.home.acept'),
              handler: () => {}
            }
          ]
        })
        .then((a) => a.present())
    },

    quoteAccepted() {
      return this.$ionic.alertController
        .create({
          cssClass: 'my-custom-class',
          header: '',
          message: `${this.$t('frontend.payment.quoteAcepted')}`,
          buttons: [
            {
              text: this.$t('frontend.home.acept'),
              handler: () => {}
            }
          ]
        })
        .then((a) => a.present())
    },
    cerrarSesion: function () {
      this.clientId = ''
      this.customerName = ''
      this.phone = ''
      this.email = ''
      this.allOrders = []

      this.$store.commit('setCustomer', {})
      this.$store.commit('setAllOrder', [])
      this.$store.commit('setAllReservations', [])
      this.$store.commit('setAllTickets', [])

      this.keyUserLogin++
      this.$ionic.modalController.dismiss()
      EventBus.$emit('updateCustomerGuess', true)
      return this.$router.push({ name: 'Home' })
    },

    goMyAccount: async function () {
      this.closeStart()
      return this.$router.push({ name: 'Myaccount' })
    },

    trackingPackage: async function () {
      this.restaurantSelected = true
      this.closeStart()
      return this.$router.push({ name: 'TrackingPackage' })
    },

    totalRating: function (res) {
      if (res.rating) {
        let totalRating = 0
        res.rating.forEach((r) => (totalRating += r.rating))
        totalRating = totalRating / res.rating.length
        return totalRating
      } else return 0
    },

    // restaurantOpen(horarios) {
    //   const day = moment.tz(moment.tz.guess()).format("dddd");
    //   const hour = moment.tz(moment.tz.guess()).format("HH:mm");

    //   const index = horarios.findIndex((re) => re.Day === day);
    //   if (index !== -1) {
    //     const openHour = moment
    //       .tz(horarios[index].OpenHour, moment.tz.guess())
    //       .format("HH:mm");
    //     const closeHour = moment
    //       .tz(horarios[index].CloseHour, moment.tz.guess())
    //       .format("HH:mm");

    //     if (hour < openHour || hour > closeHour) return false;
    //     else return true;
    //   }
    // },
    restaurantOpen(res) {
      const horarios = res.RestaurantDaysAndTime
      //console.log('restaurantOpen', horarios, res);

      const day = DateTime.now()
        .setZone(DateTime.local().zoneName)
        .toFormat('cccc')

      const now = DateTime.now().toFormat('MM-dd-yyyy')

      const index = horarios.findIndex((re) => re.Day === day)
      if (index !== -1) {
        const openDateTimeTz = now + ' ' + horarios[index].OpenHour
        const closeDateTimeTz = now + ' ' + horarios[index].CloseHour

        const openingDateTime = DateTime.fromFormat(
          openDateTimeTz,
          'MM-dd-yyyy HH:mm'
        ).setZone(res.Timezone)
        const closingDateTime = DateTime.fromFormat(
          closeDateTimeTz,
          'MM-dd-yyyy HH:mm'
        ).setZone(res.Timezone)
        //the selected date and hour should be within the open and closing time
        const selectedDateTime = DateTime.now()

        return Utils.isBetweenDates(
          selectedDateTime,
          openingDateTime,
          closingDateTime
        )
      } else {
        return false
      }
    },

    /**
     * Get the correct display of the business schedule
     */
    restaurantSchedule(res) {
      const horarios = res.RestaurantDaysAndTime
      //console.log('JQD', 'restaurantSchedule', res);
      //get the current day
      const day = DateTime.now()
        .setZone(DateTime.local().zoneName)
        .toFormat('cccc')
      //console.log('JQD', "restaurantSchedule", horarios, day);

      //find a match
      const index = horarios.findIndex((re) => re.Day === day)
      if (index !== -1) {
        const mss = `${this.getReservationHour(
          horarios[index].OpenHour,
          horarios[index].Timezone
        )} - ${this.getReservationHour(horarios[index].CloseHour, horarios[index].Timezone)}`
        return mss
      } else {
        return ''
      }
    },

    getReservationHour(thisHour, tz) {
      const now = DateTime.now().toFormat('MM-dd-yyyy')

      const dateString = now + ' ' + thisHour

      const dt = DateTime.fromFormat(dateString, 'MM-dd-yyyy HH:mm').setZone(tz)
      return dt.toFormat('h:mm a')
    },

    hasOptionToShowCatering() {
      if (
        this.$store.state.configuration.viewCatering &&
        this.$store.state.menuConCatering.length > 0 &&
        this.$store.state.restaurantActive.RestaurantBussines
      ) {
        if (this.$store.state.configuration.viewCatering) {
          if (this.$store.state.configuration.payForQuotation) {
            if (!this.$store.state.restaurantActive.hasPaymentCardConfig)
              return false
            return true
          }
          return true
        }
      }

      return false
    },

    hasCardPayRes() {
      if (this.$store.state.configuration.hasReservationQuotation) {
        if (!this.$store.state.restaurantActive.hasPaymentCardConfig)
          return false
        return true
      }
      return true
    },

    hasOptionToShowMenu() {
      //TODO: JQD to optimize and clarify
      if (
        !this.$store.state.restaurantActive.hasPaymentCardConfig &&
        !this.$store.state.configuration.viewOnTable
      )
        return false
      return true
    },

    hasOptionToShowOtherRestaurants() {
      if (this.$store.state.configuration.showOtherRestaurant) return true
      return false
    },

    hasOptionToShowTicket() {
      if (
        this.$store.state.allTickets.length > 0 &&
        this.$store.state.restaurantActive.RestaurantBussines
      )
        return true
      return false
    },

    hasOptionToShowReservation() {
      if (
        this.$store.state.configuration.viewReservation &&
        this.$store.state.configuration.viewCustomerReservation
      )
        return true
      return false
    },

    //USING STORE
    async customerUpdated() {
      const { Commons } = require('./frontend/commons')
      this.clientId = this.$store.state.customer._id
      this.customerName = this.$store.state.customer.Name
      this.email = this.$store.state.customer.EmailAddress
      this.phone = this.$store.state.customer.Phone
      this.marketingEmail = this.$store.state.customer.MarketingConsent.Email
      this.marketingPhone = this.$store.state.customer.MarketingConsent.Phone

      //COMMONS
      ;(Commons.clientId = this.clientId),
        (Commons.customerName = this.customerName)
      Commons.email = this.email
      Commons.phone = this.phone

      if (this.restaurantSelected) {
        this.spinner = true
        await Commons.getOrders()
        await Commons.getListReservation()
        await Commons.getTickets()
        await Commons.getCredit()
        // //SUBSCRIPTION
        Commons.alertSubscription(this.email, this.customerName, this.phone)
        EventBus.$emit('updateCustomerGuess', true)
        this.spinner = false
      }
    },

    timeCounterBack() {
      this.idleTrackerBack.start()
    },

    timeCounterCustomer() {
      this.idleTracker.start()
    },

    timeCounterFrontend() {
      this.idleTrackerRestartFront.start()
    },

    async shareUrlLocation() {
      try {
        await Share.share({
          title:
            'Location ' +
            ' / ' +
            this.$store.state.restaurantActive.restaurantName,
          // text: name +' / '+ this.restaurantName,
          url: this.$store.state.restaurantActive.UrlLocation,
          dialogTitle: `Share Location ${this.$store.state.restaurantActive.restaurantName}`
        })
      } catch (error) {
        this.getError(error)
      }
    },

    async getError(message) {
      return this.$ionic.toastController

        .create({
          message: message,
          duration: 2000,
          position: 'top',
          color: 'danger'
        })
        .then((a) => a.present())
    },

    async showTickets() {
      this.closeStart()
      return this.$router.push({ name: 'OrderFront' })
    },

    editUserBack: function (id) {
      this.closeEnd()
      this.$router.push({
        name: 'UserForm',
        params: {
          userId: id,
          sameConnected: true
        }
      })
    },

    goToJournal() {
      this.restaurantSelected = true
      this.closeStart()
      this.$router.push({
        name: 'Journal'
      })
    },

    showScannerModal() {
      this.$modal.show('scanner-modal')
    },

    dismissScannerModal() {
      this.$modal.hide('scanner-modal')
    },

    onDecode(result) {
      //console.log("JQD App", "onDecode", result);
      this.isScanning = false
      this.scannedItems.push({
        id: new Date().getTime(),
        content: result,
        created_at: new Date()
      })
    },

    deleteScanned(index) {
      this.scannedItems.splice(index, 1)
    }
  }
}
</script>

<style id="all-menu-styles">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.image-slide {
  display: inline !important;
  width: 100%;
  max-height: 110px !important;
  object-fit: scale-down;
}

@media screen and (max-width: 600px) {
  .image-slide {
    display: inline !important;
    width: 100%;
  }

  .content-2 {
    width: 100%;
    max-height: 150px !important;
    overflow-y: auto;
    /* Agrega desplazamiento vertical si el contenido es demasiado grande */
  }

  .titles {
    font-size: 14px;
  }
}

.height-toolbar {
  height: 170px;
}

.display-card {
  width: 100%;
  max-height: 150px;
}

.display-content {
  padding-left: 0;
  padding-right: 0;
  max-height: 150px;
}

.slider-button-left {
  margin: auto;
  text-align: start;
}

.slider-button-left button {
  color: grey;
  margin: 0;
  padding: 0;
  float: left;
  background: transparent;
}

.slider-button-left button svg {
  margin: 0;
}

.slider-button-right {
  margin: auto;
  text-align: end;
}

.slider-button-right button {
  background: transparent;
  color: grey;
  margin: 0;
  padding: 0;
  float: right;
}

.slider-button-right button svg {
  margin: 0;
}

.iconify {
  width: 20px;
}

.content {
  text-align: end;
}

.content-2 {
  text-align: start;
}

.settings {
  font-size: 30px;
}

.button-logout {
  border-radius: 100px;
  position: absolute;
  right: 20px;
  /* Coloca el botón a 20px del borde derecho */
  border: 1px solid;
}

.button-log {
  margin-top: 4px;
  float: right;
  color: white;
  border: 2px solid white;
  border-radius: 100px;
}

.title-ios {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  /* ajusta el valor según tus necesidades */
}

ion-app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.justificado {
  text-align: justify;
  color: #122538;
  hyphens: auto;
}

.scrollable-content {
  max-height: 400px;
  /* ajusta la altura máxima según tus necesidades */
  overflow-y: auto;
}

.center {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  #title {
    font-size: 20px;
  }

  .center {
    width: 100%;
    display: contents;
    justify-content: left;
  }
}

.select-icon-inner {
  opacity: 0.9 !important;
}

/* .alert-input.sc-ion-alert-md, .alert-input.sc-ion-alert-ios{
border-bottom: 1px solid #da0f0f;
} */

.alert-input-wrapper:before {
  /* content: "*"; */
  /* color: red; */
  float: left;
  margin-bottom: -15px;
}

.alert-message.sc-ion-alert-md {
  -webkit-user-modify: read-write-plaintext-only;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #3880ff;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
  color: var(--color);
  user-select: none;
}

#app1 {
  z-index: 20;
  /*margin: 0.5px 0.5px 10px 0.5px;*/
}

.menu-col-8 {
  flex: 0 0 calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(8 / var(--ion-grid-columns, 12)) * 100%);
}

.menu-col-9 {
  flex: 0 0 calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(9 / var(--ion-grid-columns, 12)) * 100%);
}

.menu-col-10 {
  flex: 0 0 calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(10 / var(--ion-grid-columns, 12)) * 100%);
}

.menu-col-2 {
  flex: 0 0 calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(2 / var(--ion-grid-columns, 12)) * 100%);
}

.menu-col-3 {
  flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
}

.menu-col-4 {
  flex: 0 0 calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(4 / var(--ion-grid-columns, 12)) * 100%);
}

.menu-col-12 {
  flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
  width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
  max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
}

.iconify {
  width: 30px;
  height: 30px;
  margin: 2px 10px;
}

ion-button {
  max-height: 48px !important;
}

.swiper-wrapper {
  padding-bottom: 30px !important;
}

.ion-page {
  overflow: auto !important;
}

.loading-wrapper.sc-ion-loading-md {
  opacity: 1;
}

.inner-scroll {
  overflow-y: visible !important;
}

ion-col {
  border: none !important;
}

/*
ion-input,
ion-textarea {
  --background: #8080801c !important;
}
*/

button {
  /* --background-hover: var(--ion-color-primary) !important; */
  /* --background-hover: rgb(204, 195, 195); */
}

:root {
  --ion-safe-area-top: 40px;
  --ion-safe-area-bottom: 22px;
}

.subtitle {
  color: darkgray;
  font-size: 12px;
}

.clickable {
  cursor: pointer;
}
.clickable:hover {
  text-decoration: underline; /* Para dar una indicación visual adicional cuando el cursor está sobre el enlace */
}

/* To determine if which message to display for motivation */
.desktop-only {
  display: none;
}

.mobile-only {
  display: block;
}

/* Use media queries to control display on different screen sizes */
@media (min-width: 768px) {
  .desktop-only {
    display: block;
  }
  .mobile-only {
    display: none;
  }
}
</style>
